<template lang="pug">
b-dropdown#yearFilter.m-2(text="Año", variant="dropdown", ref="dropdown")
  b-dropdown-form(style="width:350px;")
    b-row
      b-col
        .h5.mb-4 Rango de fechas
        vue-slider(
          v-model="yearSlider",
          :enable-cross="false",
          :min="1961",
          :max="2024",
        )
  b-dropdown-divider
  b-dropdown-form
    b-row
      b-col(cols="6")
        label Desde
        b-form-input.w-100(type="number", v-model="yearSlider[0]", min="1961", max="2021")
      b-col(cols="6")
        label Hasta
        b-form-input.w-100(type="number", v-model="yearSlider[1]", min="1962", max="2024")
  b-dropdown-divider
  b-dropdown-form
    b-row
      b-col.text-right
        b-button(variant="outline-mhmotors" @click="closeDropdownYear").ml-1 Cancelar
        b-button(variant="mhmotors ml-1" @click="changeYear") Aplicar
</template>
<script>
import { mapState, mapMutations } from "vuex";
import VueSlider from "vue-slider-component";

export default {
  name: "FilterDate",
  components: {
    VueSlider,
  },
  data() {
    return {
      yearSlider: [1961, 2024],
    };
  },
  mounted() {
    if (this.$route.query.years) {
      this.yearSlider = this.$route.query.years
        .split(",", 2)
        .map((num) => parseInt(num, 10));
      this.activeButton();
    }
    this.SET_YEARS(this.yearSlider);
    this.closeDropdownYear();
    this.CLEAR_PRODUCTS();
    this.SET_PAGE(1);
    this.CHANGE_TYPE();
  },
  computed: {
    ...mapState("filter", ["keywords", "products", "filters"]),
  },
  methods: {
    ...mapMutations("filter", ["SET_YEARS", "SET_PAGE", "CLEAR_PRODUCTS", "CHANGE_TYPE"]),
    changeYear() {
      this.SET_YEARS(this.yearSlider.map((num) => parseInt(num, 10)));
      this.activeButton();
      this.closeDropdownYear();
      this.setRouter();
      this.CLEAR_PRODUCTS();
      this.SET_PAGE(1);
      this.CHANGE_TYPE();
    },
    activeButton() {
      const yearsFilter = document.querySelector("#yearFilter button");
      yearsFilter.classList.add("btn-active");
    },
    closeDropdownYear() {
      const yearsFilter = document.querySelector("#yearFilter ul.dropdown-menu");
      yearsFilter.classList.remove("show");
    },
    setRouter() {
      let query = this.$route.query;
      let newPath = `filters?`;
      if (this.yearSlider) {
        query.years = JSON.stringify(this.yearSlider).slice(1, -1);
        console.log(query.years);
      } else {
        delete query.years;
      }
      Object.keys(query).forEach((key) => {
        newPath += `${key}=${query[key]}&`;
      });
      if (this.$route.fullPath !== newPath)
        this.$router.push("/", () => {
          this.$router.push({ path: newPath.slice(0, -1) });
        });
    },
  },
};
</script>
<style lang="scss">
@import "~vue-slider-component/lib/theme/default.scss";
@import "@/assets/scss/_variables";

#yearFilter {
  > button {
    border: 1px solid #e8e8e8;
    color: #999999;
  }
  .vue-slider {
    &-process {
      background-color: $primary;
    }
    &-dot {
      height: 25px !important;
      width: 25px !important;
      &-tooltip-inner {
        border-color: $primary;
        background-color: $primary;
      }
    }
  }
}
</style>
