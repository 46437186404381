<template lang="pug">
multiselect(
  v-model.trim="transmission",
  deselect-label="Borrar",
  select-label="Seleccionar",
  track-by="value",
  label="name",
  placeholder="Transmisión",
  :searchable="true",
  :allow-empty="true",
  class="form__select",
  :options="transmissions",
  :class="transmission ? 'active':''"
)
</template>
<script>
import { mapMutations } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "FiterTransmission",
  components: {
    Multiselect,
  },
  data() {
    return {
      transmission: "",
      transmissions: [
        { name: "Automático", value: "automatico", icon: "icon-automatic" },
        { name: "Manual", value: "manual", icon: "icon-mechanic" },
      ],
    };
  },
  mounted() {
    if (this.$route.query.transmission) {
      this.transmission = this.transmissions.find(
        (todo) => todo.value === this.$route.query?.transmission
      );
    }
    this.SET_TRANSMISSION(this.transmission?.value);
    this.setRouter();
    this.CLEAR_PRODUCTS();
    this.SET_PAGE(1);
    this.CHANGE_TYPE();
  },
  methods: {
    ...mapMutations("filter", [
      "SET_TRANSMISSION",
      "SET_PAGE",
      "CLEAR_PRODUCTS",
      "CHANGE_TYPE",
    ]),
    setRouter() {
      let query = this.$route.query;
      let newPath = `filters?`;
      if (this.transmission) {
        query.transmission = this.transmission?.value;
      } else {
        delete query.transmission;
      }
      Object.keys(query).forEach((key) => {
        newPath += `${key}=${query[key]}&`;
      });
      if (this.$route.fullPath !== newPath)
        this.$router.push("/", () => {
          this.$router.push({ path: newPath.slice(0, -1) });
        });
    },
  },
  watch: {
    transmission(transmission) {
      if (transmission) {
        this.transmission = transmission;
      }
      this.SET_TRANSMISSION(this.transmission?.value);
      this.setRouter();
      this.CLEAR_PRODUCTS();
      this.SET_PAGE(1);
      this.CHANGE_TYPE();
    },
  },
};
</script>
