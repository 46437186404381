<template lang="pug">
b-container.fluid
	b-row.justify-content-center.align-items-start
		nav-bar-filter-automotive-components
		b-col(cols="12").mt-5.pt-4
			b-breadcrumb(:items="breadcrumb").mt-3
		b-col.mt-4(v-if="loading", cols="12" lg="9")
			.d-flex.justify-content-center.my-5.pt-2
					b-spinner(variant="info", label="Loading..")
		b-col(v-else cols="12" lg="11" xl="9").min-vh-100-main
			products-filter-result-components
</template>
<script>
import Multiselect from "vue-multiselect";
import { mapState, mapGetters } from "vuex";
import NavBarFilterAutomotiveComponents from "./NavBarFilterAutomotiveComponents";
import ProductsFilterResultComponents from "./ProductsFilterResultComponents";

export default {
  name: "NavBarFilter",
  components: {
    Multiselect,
    NavBarFilterAutomotiveComponents,
    ProductsFilterResultComponents,
  },
  data() {
    return {
      name: null,
      bodywork: {},
      breadcrumb: [],
      load: true,
    };
  },
  created() {
    this.bodywork = this.getCurrentBodywork(this.$route.query?.bodywork ?? "all");
  },
  watch: {
    "$route.query.bodywork": function () {
      this.bodywork = this.getCurrentBodywork(this.$route.query?.bodywork ?? "all");
      this.getBreadcrumb();
    },
  },
  computed: {
    ...mapState(["loading"]),
    ...mapGetters("bodywork", ["getCurrentBodywork"]),
  },
  mounted() {
    this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      this.breadcrumb = [
        {
          text: "Inicio",
          to: { name: "inicio" },
        },
        {
          text: `Carroceria ${this.bodywork.name}`,
          to: "",
        },
      ];
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.min-vh-100-main {
  min-height: calc(100vh - 376px);
}
.card {
  border-radius: 20px;
}
.list-group-item {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.5rem;
}
#mainCategoryCatalog {
  .multiselect,
  .multiselect__input,
  .multiselect__single {
    font-size: 14px;
  }
  .multiselect {
    &__tags {
      border: none !important;
    }
  }
}
</style>
