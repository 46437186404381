<template lang="pug">
multiselect(
  v-model="bodywork",
  track-by="name",
  label="name",
  placeholder="Carroceria",
  select-label="Seleccionar",
  deselect-label="Borrar",
  :searchable="true",
  :allow-empty="true",
  :options="bodyworks",
  :class="bodywork ? 'active':''"
)
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "FilterBodywork",
  components: {
    Multiselect,
  },
  data() {
    return {
      bodywork: "",
    };
  },
  created() {
    this.getBodyworks();
  },
  mounted() {
    this.bodywork = this.getCurrentBodywork(this.$route.query?.bodywork ?? "all");
    this.SET_BODYWORK(this.bodywork?.id ?? 0);
  },
  computed: {
    ...mapState("bodywork", ["bodyworks"]),
    ...mapGetters("bodywork", ["getCurrentBodywork"]),
  },
  methods: {
    ...mapActions("bodywork", ["getBodyworks"]),
    ...mapMutations("filter", [
      "SET_BODYWORK",
      "SET_PAGE",
      "CLEAR_PRODUCTS",
      "CHANGE_TYPE",
    ]),
    setRouter() {
      let query = this.$route.query;
      let newPath = `filters?`;
      query.bodywork = this.bodywork?.slug ?? "all";
      Object.keys(query).forEach((key) => {
        newPath += `${key}=${query[key]}&`;
      });
      if (this.$route.fullPath !== newPath)
        this.$router.push("/", () => {
          this.$router.push({ path: newPath.slice(0, -1) });
        });
    },
  },
  watch: {
    bodywork(bodywork) {
      this.bodywork = bodywork ?? this.getCurrentBodywork("all");
      this.SET_BODYWORK(this.bodywork?.id ?? 0);
      this.setRouter();
      this.CLEAR_PRODUCTS();
      this.SET_PAGE(1);
      this.CHANGE_TYPE();
    },
    "$route.query.bodywork": function (bodywork) {
      if (this.bodywork !== bodywork) {
        this.bodywork = this.getCurrentBodywork(bodywork);
      }
    },
  },
};
</script>
