<template lang="pug">
multiselect(
  v-model="model",
  track-by="name",
  label="name",
  placeholder="Modelo",
  select-label="Seleccionar",
  deselect-label="Borrar",
  :searchable="true",
  :allow-empty="true",
  :options="models",
  :class="model ? 'active':''"
)
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "FilterModel",
  components: {
    Multiselect,
  },
  data() {
    return {
      brand: "",
      model: "",
    };
  },
  created() {
    this.brand = this.$route.query?.brand ?? 0;
    if (this.brand) this.getModels(parseInt(this.brand, 10));
  },
  mounted() {
    this.model = this.getModelById(parseInt(this.$route.query?.model, 10));
    if (this.brand) {
      this.setRouter();
      this.SET_MODEL(this.model?.id ?? 0);
    } else {
      this.cleanModels();
      this.SET_MODEL(0);
    }
  },
  computed: {
    ...mapGetters("model", ["getModelById"]),
    ...mapState("model", ["models"]),
  },
  methods: {
    ...mapActions("model", ["getModels", "cleanModels"]),
    ...mapMutations("filter", ["SET_MODEL", "SET_PAGE", "CLEAR_PRODUCTS", "CHANGE_TYPE"]),
    async setRouter() {
      let query = this.$route.query;
      let newPath = `filters?`;
      if (this.model) {
        query.model = this.model?.id ?? 0;
      } else {
        delete query.model;
      }
      await Object.keys(query).forEach((key) => {
        newPath += `${key}=${query[key]}&`;
      });
      if (this.$route.fullPath !== newPath)
        this.$router.push("/", () => {
          this.$router.push({ path: newPath.slice(0, -1) });
        });
    },
  },
  watch: {
    model(model) {
      this.model = model;
      this.SET_MODEL(this.model?.id);
      this.setRouter();
      this.CLEAR_PRODUCTS();
      this.SET_PAGE(1);
      this.CHANGE_TYPE();
    },
    "$route.query.brand": function (brand) {
      if (brand) {
        this.model = "";
        this.getModels(parseInt(brand, 10));
      } else {
        this.SET_MODEL(0);
      }
    },
  },
};
</script>
