<template lang="pug">
multiselect(
  v-model.trim="brand",
  track-by="name",
  label="name",
  placeholder="Marca",
  select-label="Seleccionar",
  deselect-label="Borrar",
  :searchable="true",
  :allow-empty="true",
  :options="brands",
  :class="brand ? 'active':''"
)
</template>
<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "FilterBrand",
  components: {
    Multiselect,
  },
  data() {
    return {
      brand: "",
    };
  },
  created() {
    //this.getBrands()
    this.brand = this.getBrandById(parseInt(this.$route.query.brand, 10));
    this.SET_BRAND(this.brand?.id);
  },
  computed: {
    ...mapState("brand", ["brands"]),
    ...mapGetters("brand", ["getBrandById"]),
  },
  methods: {
    //...mapActions('brand',['getBrands']),
    ...mapMutations("filter", ["SET_BRAND", "SET_PAGE", "CLEAR_PRODUCTS", "CHANGE_TYPE"]),
    async setRouter() {
      let query = this.$route.query;
      let newPath = `filters?`;
      if (this.brand) {
        query.brand = this.brand?.id;
      } else {
        delete query.brand;
      }
      await Object.keys(query).forEach((key) => {
        newPath += `${key}=${query[key]}&`;
      });
      if (this.$route.fullPath !== newPath)
        this.$router.push("/", () => {
          this.$router.push({ path: newPath.slice(0, -1) });
        });
    },
  },
  watch: {
    brand(brand) {
      this.brand = brand;
      this.SET_BRAND(this.brand?.id);
      this.setRouter();
      this.CLEAR_PRODUCTS();
      this.SET_PAGE(1);
      this.CHANGE_TYPE();
    },
  },
};
</script>
