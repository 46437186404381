<template lang="pug">
#product-result.pb-5
	router-link.text-decoration-none.link-product(
		v-for="(product, index) in products",
		:key="index",
		:disabled="product.sold"
		:is="product.sold ? 'span' : 'router-link'"
		:to="{ name: 'detailed-product', params: { slug: product.slug , hashid: Hashids.encode(product.id) }}"
	)
		ribbon(:sold="product.sold", :title="product.label_name", :color="product.label_color" :top='-8' :size='0.7')
		.card.card-product.mb-3.flex-md-row(v-bind:class="{ sold: product.sold }")
			b-img-lazy(
				v-bind="mainProps"
				:src="getThumbnail()"
				:srcset='getThumbnail(product.images)',
				:alt="product.vehicles_products.brand.name+' '+product.vehicles_products.modelo.name+' '+product.vehicles_products.version+' '+product.vehicles_products.year"
			)
			.card-body
				h3.card-title(v-text="product.vehicles_products.brand.name+' '+product.vehicles_products.modelo.name+' '+product.vehicles_products.version+' '+product.vehicles_products.year")
				b-row
					b-col(cols="12" md="4" lg="4" xl="3" v-if="product.price")
						p.text-muted.text-uppercase Precio
						h4.card-subtitle ${{ product.price | formatNumber }}
				b-row.text-center.product-detail-vehicle.mt-1
					b-col.item(v-if="product.vehicles_products.bodywork.name")
						b-badge.d-block(pill variant="light" v-text="product.vehicles_products.bodywork.name")
					b-col.item(v-if="product.vehicles_products.mileage")
						b-badge.d-block(pill variant="light") {{ product.vehicles_products.mileage | formatNumber }} Kms
					b-col.item(v-if="product.vehicles_products.displacement")
						b-badge.d-block(pill variant="light" v-text="product.vehicles_products.displacement + ' cc'")
					b-col.item(v-if="product.vehicles_products.fuel")
						b-badge.d-block.text-capitalize(pill variant="light" v-text="product.vehicles_products.fuel")
					b-col.item(v-if="product.vehicles_products.transmission")
						b-badge.d-block.text-capitalize(pill variant="light" v-text="product.vehicles_products.transmission")
					b-col.item(v-if="product.vehicles_products.traction")
						b-badge.d-block.text-capitalize(pill variant="light" v-text="product.vehicles_products.traction")
				.card-text.mt-2 {{ product.description }}
				b-button.btn-credit(variant="mhmotors" size="sm" v-if="product.vehicles_products.payment_type === 2 && !product.sold" @click.stop.prevent="creditVehicle(product)")
					font-awesome-icon(icon="credit-card")
					|  Simula tu financiamiento
	infinite-loading(:identifier="infiniteId", @infinite="getProducts", spinner="spiral", force-use-infinite-wrapper="true")
		template(slot="no-more") No hay mas vehículos por el momento, pero esta atento a la publicación de nuevos vehiculos.
		template(slot="no-results") No hay vehículos por el momento con estas características, pero esta atento a la publicación de nuevos vehiculos.
	footer-navbar-mobile(v-if="(isAdmin||isProfessional)")
</template>
<script>
import Hashids from "hashids";
import InfiniteLoading from "vue-infinite-loading";
import { mapActions, mapMutations, mapState } from "vuex";
import FooterNavbarMobile from "../FooterNavbarMobile";
import Ribbon from "../product/Ribbon";
export default {
  name: "ResultadoFiltrosProductos",
  components: {
    FooterNavbarMobile,
    InfiniteLoading,
    Ribbon,
  },
  data() {
    return {
      Hashids: new Hashids(
        process.env.VUE_APP_HASHIDS_SALT,
        10,
        process.env.VUE_APP_HASHIDS_ALPHABET
      ),
      urlImg: `${this.$axios.defaults.baseURL}`,
      mainProps: {
        center: true,
        // fluidGrow: true,
        blank: true,
        blankColor: "#282b2d",
        width: 200,
        // height: 240
      },
    };
  },
  filters: {
    formatNumber: function (value) {
      if (!value) return "";
      value = value.toString().replace(/\./g, "");
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    cleanString: function (valueString) {
      if (!valueString) return "";
      valueString = valueString.toString();
      const regex = /([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g;
      return valueString.replace(regex, "");
    },
  },
  watch: {},
  mounted() {
    this.SET_PAGE(1);
    this.CLEAR_PRODUCTS();
  },
  computed: {
    ...mapState("filter", ["products", "filters", "pagination_complete", "infiniteId"]),
    ...mapState("auth", ["isAdmin", "isProfessional"]),
  },
  methods: {
    ...mapMutations("filter", ["SET_PAGE", "CLEAR_PRODUCTS"]),
    ...mapMutations(["SHOW_LOADING", "HIDE_LOADING"]),
    ...mapActions("filter", ["getProducts"]),
    getThumbnail(images) {
      if (Array.isArray(images) && images.length > 0) {
        return images[0].thumbnail;
      } else {
        return "https://api.mhmotors.cl/images/no_image_thumb.jpg";
      }
    },
    creditVehicle(product) {
      let slug = product.slug;
      let hashid = this.Hashids.encode(product.id);
      this.$router.push({ path: `/credits/${slug}/${hashid}` });
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/variables";

#product-result {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .link-product {
    position: relative;
    &:hover {
      .silk-ribbon {
        transform: translateY(-2px);
      }
    }
  }
  .card {
    overflow: hidden;
    border-radius: 20px;
    &.sold {
      cursor: no-drop;
      img {
        filter: grayscale(1);
      }
      &::before {
        background-color: #000;
        border-radius: 10px;
        color: white;
        content: "VENDIDO";
        font-size: 1.2rem;
        font-weight: 500;
        left: 25px;
        margin: 0 auto;
        padding: 5px 20px;
        position: absolute;
        text-align: center;
        top: 60px;
        transform: rotate(-35deg);
        width: 160px;
        z-index: 1;
        @media (max-width: 768px) {
          top: 45px;
          left: 0;
          right: 0;
        }
      }
    }
    @media (max-width: 768px) {
      //width: 49.5%;
      max-width: 180px;
      margin: 1rem 0;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    img {
      border-top-left-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px);
      max-width: 200px;
      @media (max-width: 768px) {
        border-top-left-radius: calc(0.25rem - 1px);
        border-top-right-radius: calc(0.25rem - 1px);
        max-width: 100%;
      }
    }
    &-title {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 0.5rem;
      margin-right: 35%;
      //color: $orange;
      @media (max-width: 768px) {
        margin-right: 0;
        font-size: 0.95rem;
      }
    }
    &-subtitle {
      font-weight: 600;
      margin-bottom: 0.1rem;
      font-size: 1.1rem;
      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-height: 1.5;
      -webkit-line-clamp: 2; /* number of lines to show */
      margin-bottom: 0.3rem;
      -webkit-box-orient: vertical;
      @media (max-width: 768px) {
        min-height: 38px;
        margin-bottom: 10px;
        font-size: 0.9rem;
      }
    }
    &-body {
      padding: 0.5rem 0.8rem;
      font-size: 0.85rem;
      @media (min-width: 768px) {
        width: 68%;
      }
      @media (max-width: 768px) {
        padding: 0.5rem 0.6rem;
      }
      .text-muted {
        font-size: 0.6rem;
        margin-bottom: 0.5rem;
        @media (max-width: 768px) {
          font-size: 0.65rem;
        }
      }
      .product-detail-vehicle {
        @media (max-width: 768px) {
          display: none;
        }
      }
      .btn-credit {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 5px 20px;
        @media (max-width: 768px) {
          position: static;
          font-size: 0.8rem;
          display: block;
          width: 100%;
          padding: 0.5rem;
        }
      }
    }
    &-product {
      color: #555555 !important;
      text-decoration: none !important;
      transition: all 0.3s ease;
      margin-bottom: 20px;
      height: 160px;
      @media (max-width: 768px) {
        height: auto;
      }
      &:hover {
        transform: translateY(-2px);
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.2);
      }
    }
    .product-detail-vehicle {
      .item {
        max-width: 95px;
        padding: 3px;
        //border-left: #eeeeee 1px solid;
        &:first-child {
          margin-left: 10px;
        }
        //&:last-child {
        //border-right: #eeeeee 1px solid;
        //}
      }
      .icon {
        display: block;
        height: 30px;
        &:before {
          vertical-align: middle;
        }
        &-citycar,
        &-hatchback,
        &-coupe,
        &-sedan,
        &-convertible,
        &-station-wagon,
        &-suv,
        &-van,
        &-pickup-truck,
        &-truck,
        &-bus,
        &-machinery,
        &-motorcycle {
          font-size: 1.8rem;
          margin-top: -5px;
        }
        &-seat,
        &-doors,
        &-engine,
        &-road,
        &-automatic,
        &-mechanic,
        &-gasoline,
        &-petroleum,
        &-electric,
        &-gas,
        &-hibrido {
          font-size: 1.5rem;
        }
      }
    }
  }
  .infinite-loading-container {
    width: 100%;
  }
  .loading-spiral {
    border-color: $primary !important;
    border-right-color: transparent !important;
  }
}
.silk-ribbon {
  transition: all 0.3s ease;
  background: #00b3ed;
  box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
  position: absolute;
  top: 15px;
  z-index: 2;
  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
  }
  &:before {
    width: 7px;
    height: 120%;
    padding: 0 0 7px;
    top: 0;
    left: -7px;
    background: inherit;
    border-radius: 5px 0 0 5px;
  }
  &:after {
    width: 5px;
    height: 5px;
    background: rgba(0, 0, 0, 0.35);
    bottom: -5px;
    left: -5px;
    border-radius: 5px 0 0 5px;
  }
}
</style>
