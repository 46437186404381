<template lang="pug">
multiselect(
  v-model="seat",
  deselect-label="Borrar",
  select-label="Seleccionar",
  placeholder="Asientos",
  :searchable="true",
  :allow-empty="true",
  class="form__select",
  :options="seats",
  :class="seat ? 'active':''"
)
</template>
<script>
import { mapMutations } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "FiterSeat",
  components: {
    Multiselect,
  },
  data() {
    return {
      seat: "",
      seats: [1, 2, 3, 4, 5, 6, 7, "O Más"],
    };
  },
  mounted() {
    if (this.$route.query.seat) {
      this.seat =
        this.seats.find((todo) => todo === parseInt(this.$route.query?.seat, 10)) ?? "";
    }
    this.SET_SEAT(this.seat);
    this.setRouter();
    this.CLEAR_PRODUCTS();
    this.SET_PAGE(1);
    this.CHANGE_TYPE();
  },
  methods: {
    ...mapMutations("filter", ["SET_SEAT", "SET_PAGE", "CLEAR_PRODUCTS", "CHANGE_TYPE"]),
    setRouter() {
      let query = this.$route.query;
      let newPath = `filters?`;
      if (this.seat) {
        query.seat = this.seat;
      } else {
        delete query.seat;
      }
      Object.keys(query).forEach((key) => {
        newPath += `${key}=${query[key]}&`;
      });
      if (this.$route.fullPath !== newPath)
        this.$router.push("/", () => {
          this.$router.push({ path: newPath.slice(0, -1) });
        });
    },
  },
  watch: {
    seat(seat) {
      if (seat) {
        this.seat = parseInt(seat, 10);
      }
      this.SET_SEAT(this.seat);
      this.setRouter();
      this.CLEAR_PRODUCTS();
      this.SET_PAGE(1);
      this.CHANGE_TYPE();
    },
  },
};
</script>
