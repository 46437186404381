<template lang="pug">
multiselect(
  v-model.trim="traction",
  deselect-label="Borrar",
  select-label="Seleccionar",
  placeholder="Tracción",
  :searchable="true",
  :allow-empty="true",
  track-by="value",
  label="name",
  class="form__select",
  :options="tractions",
  :class="traction ? 'active':''"
)
</template>
<script>
import { mapMutations } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "FiterTraction",
  components: {
    Multiselect,
  },
  data() {
    return {
      traction: "",
      tractions: [
        { name: "4X2", value: "4x2", icon: "icon-x2" },
        { name: "4X4", value: "4x4", icon: "icon-x4" },
        { name: "AWD", value: "awd", icon: "icon-awd" },
      ],
    };
  },
  mounted() {
    if (this.$route.query.traction) {
      this.traction = this.tractions.find(
        (todo) => todo.value === this.$route.query?.traction
      );
    }
    this.SET_TRACTION(this.traction?.value);
    this.setRouter();
    this.CLEAR_PRODUCTS();
    this.SET_PAGE(1);
    this.CHANGE_TYPE();
  },
  methods: {
    ...mapMutations("filter", [
      "SET_TRACTION",
      "SET_PAGE",
      "CLEAR_PRODUCTS",
      "CHANGE_TYPE",
    ]),
    setRouter() {
      let query = this.$route.query;
      let newPath = `filters?`;
      if (this.traction) {
        query.traction = this.traction?.value;
      } else {
        delete query.traction;
      }
      Object.keys(query).forEach((key) => {
        newPath += `${key}=${query[key]}&`;
      });
      if (this.$route.fullPath !== newPath)
        this.$router.push("/", () => {
          this.$router.push({ path: newPath.slice(0, -1) });
        });
    },
  },
  watch: {
    traction(traction) {
      if (traction) {
        this.traction = traction;
      }
      this.SET_TRACTION(this.traction?.value);
      this.setRouter();
      this.CLEAR_PRODUCTS();
      this.SET_PAGE(1);
      this.CHANGE_TYPE();
    },
  },
};
</script>
