<template lang="pug">
multiselect(
  v-model.trim="fuel",
  deselect-label="Borrar",
  select-label="Seleccionar",
  placeholder="Combustible",
  :searchable="true",
  :allow-empty="true",
  track-by="value",
  label="name",
  class="form__select",
  :options="fuels",
  :class="fuel ? 'active':''"
)
</template>
<script>
import { mapMutations } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "FiterFuel",
  components: {
    Multiselect,
  },
  data() {
    return {
      fuel: "",
      fuels: [
        { name: "Bencina", value: "bencina", icon: "icon-gasoline" },
        { name: "Diesel", value: "diesel", icon: "icon-petroleum" },
        { name: "Electrico", value: "electrico", icon: "icon-electric" },
        { name: "Gas", value: "gas", icon: "icon-gas" },
        { name: "Hibrido", value: "hibrido", icon: "icon-hibrido" },
      ],
    };
  },
  mounted() {
    if (this.$route.query.fuel) {
      this.fuel = this.fuels.find((todo) => todo.value === this.$route.query?.fuel);
    }
    this.SET_FUEL(this.fuel?.value);
    this.setRouter();
    this.CLEAR_PRODUCTS();
    this.SET_PAGE(1);
    this.CHANGE_TYPE();
  },
  methods: {
    ...mapMutations("filter", ["SET_FUEL", "SET_PAGE", "CLEAR_PRODUCTS", "CHANGE_TYPE"]),
    setRouter() {
      let query = this.$route.query;
      let newPath = `filters?`;
      if (this.fuel) {
        query.fuel = this.fuel?.value;
      } else {
        delete query.fuel;
      }
      Object.keys(query).forEach((key) => {
        newPath += `${key}=${query[key]}&`;
      });
      if (this.$route.fullPath !== newPath)
        this.$router.push("/", () => {
          this.$router.push({ path: newPath.slice(0, -1) });
        });
    },
  },
  watch: {
    fuel(fuel) {
      if (fuel) {
        this.fuel = fuel;
      }
      this.SET_FUEL(this.fuel?.value);
      this.setRouter();
      this.CLEAR_PRODUCTS();
      this.SET_PAGE(1);
      this.CHANGE_TYPE();
    },
  },
};
</script>
