<template lang="pug">
b-navbar#filter-top(variant="white", fixed="top")
	b-navbar-toggle#button-filter(target="nav-filter-collapse")
		template(v-slot:default="{ expanded }")
			div.mr-1(v-if="expanded") Ocultar
				i.far.fa-times-circle
			div.mr-1(v-else) Filtros
				i.fa.fa-search
	b-collapse#nav-filter-collapse(is-nav)
		b-nav-form
			//filter-region
			//filter-commune
			filter-bodywork
			filter-brand
			filter-model
			filter-date
			filter-price
			filter-transmission
			filter-traction
			filter-fuel
			filter-seat
</template>
<script>
import { mapState, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";
import VueSlider from "vue-slider-component";
//import FilterRegion from './FilterRegion'
//import FilterCommune from './FilterCommune'
import FilterBodywork from "./FilterBodywork";
import FilterBrand from "./FilterBrand";
import FilterModel from "./FilterModel";
import FilterDate from "./FilterDate";
import FilterPrice from "./FilterPrice";
import FilterTransmission from "./FilterTransmission";
import FilterTraction from "./FilterTraction";
import FilterFuel from "./FilterFuel";
import FilterSeat from "./FilterSeat";

export default {
  name: "NavBarFilterAutomotive",
  components: {
    Multiselect,
    VueSlider,
    //FilterRegion,
    //FilterCommune,
    FilterBodywork,
    FilterBrand,
    FilterModel,
    FilterDate,
    FilterPrice,
    FilterTransmission,
    FilterTraction,
    FilterFuel,
    FilterSeat,
  },
  data() {
    return {
      form: {
        category_id: 1,
        category_classification_id: 1,
        bodywork: null,
        region: null,
        commune: null,
        traction: "",
        transmission: "",
        fuel: "",
        seats: "",
        timePublished: "",
        prices: "",
        brand: "",
        model: "",
        years: "",
      },
      arrayTimePublished: ["", 1, 7, 30], //Dias
    };
  },
  computed: {
    ...mapState("filter", ["keywords", "products", "filters"]),
  },
  methods: {
    // Mutations
    ...mapMutations("filter", [
      "SET_FILTERS",
      "SET_PAGE",
      "CLEAR_PRODUCTS",
      "CHANGE_TYPE",
    ]),
    ...mapMutations(["SHOW_LOADING", "HIDE_LOADING"]),
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  watch: {
    "$route.query": function () {
      this.scrollToTop();
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "../../assets/scss/variables";

body::-webkit-scrollbar {
  width: 9px;
}
body {
  scrollbar-width: thin;
  scrollbar-color: $primary;
}
body::-webkit-scrollbar-track {
  background: #cccccc;
}
body::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 6px;
  border: 1px solid #cccccc;
}
#filter-top {
  line-height: 1;
  margin-top: 40px;
  padding: 0 0.5rem;
  z-index: 3;
  @media (max-width: 576px) {
    height: 80px;
  }
  @media (min-width: 576px) {
    margin-top: 55px;
  }
  form:focus {
    outline: none !important;
  }
  #nav-filter-collapse {
    overflow-x: scroll;
    scrollbar-color: $primary;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary;
      border-radius: 6px;
      border: 1px solid #cccccc;
    }
  }
  form.form-inline {
    flex-wrap: nowrap;
    padding: 5px 0;
  }
  .dropdown {
    position: relative;
    &-menu {
      position: fixed;
      top: 130px;
      left: auto;
      @media (max-width: 992px) {
        left: 5%;
      }
    }
  }
  .btn-active {
    background-color: $primary;
    color: white;
  }
  .multiselect {
    &,
    &__input,
    &__single {
      width: auto;
      margin-right: 0.5rem;
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    &.active {
      .multiselect__placeholder {
        color: white;
      }
    }
    &__content-wrapper {
      position: fixed;
      min-width: 280px;
      max-width: 370px;
      z-index: 10;
      @media (max-width: 768px) {
        left: 5px;
      }
    }
    &__single {
      white-space: nowrap;
      @media (max-width: 760px) {
        line-height: 15px;
      }
    }
    &__input {
      white-space: nowrap;
      @media (max-width: 760px) {
        margin-top: 3px;
        line-height: 20px;
      }
    }
    &--active {
      .multiselect__input {
        width: auto !important;
      }
    }
  }
}
#button-filter {
  background: $bg-gradient-primary;
  color: white;
  width: 100%;
  border-radius: 20px;
}
.btn-dropdown {
  color: #adadad;
  min-height: 40px;
  display: block;
  padding: 9px 14px;
  border-radius: 20px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}
.vue-slider {
  &-process {
    background-color: $primary;
  }
  &-dot {
    height: 25px !important;
    width: 25px !important;
    &-tooltip-inner {
      border-color: $primary;
      background-color: $primary;
    }
  }
}
.list-group-item {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.5rem;
}
.navbar-toggler {
  font-size: 0.8rem;
  .svg-inline--fa.fa-w-16 {
    vertical-align: middle;
  }
}
@media (max-width: 576px) {
  .btn-sm-block {
    display: block !important;
    width: 100% !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem;
  }
  .mb-sm-3 {
    margin-bottom: 1rem;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
</style>
